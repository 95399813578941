import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { courseByAbbreviation, courseCanEnroll, courseEnroll } from 'dsoapi';
import DsoaBanner from './DsoaBanner';
import {
  NextGatherInfoUrl,
  RegistrationSteps,
  Video,
  loggedIn,
  useAdSource,
} from 'dsoa-components';

const CourseDetails = ({ setTitle = () => {} }) => {
  const [course, setCourse] = useState(null);
  const [isEnrolling, setIsEnrolling] = useState(false);
  const { courseAbbreviation } = useParams();
  const navigate = useNavigate();
  useAdSource();

  useEffect(() => {
    const fetchCourse = async () => {
      const response = await courseByAbbreviation(courseAbbreviation);
      setCourse(response.data);
      setTitle(response.data.displayName);
    };
    fetchCourse();
  }, [setTitle, courseAbbreviation]);

  const onEnroll = async () => {
    if (isEnrolling) {
      return;
    }
    setIsEnrolling(true);
    const canEnrollRes = await courseCanEnroll(course.abbreviation);
    if (canEnrollRes.data.canEnroll) {
      const enrollRes = await courseEnroll(course.abbreviation, {
        siteRegisteredWith: 'dsoa',
      });
      const url = await NextGatherInfoUrl(
        enrollRes.data.userCourseEnrollmentId
      );
      navigate(url);
    } else {
      setIsEnrolling(false);
      navigate('/dashboard');
    }
  };

  const onSignup = () => {
    localStorage.setItem('signupCourse', course.abbreviation);
    navigate('/signup');
  };

  return (
    <>
      <DsoaBanner text="Driving School of America" />
      <div className="ui main container">
        <div className="course-details">
          <RegistrationSteps step={2} linkSelectCourse={true} />
          {course && (
            <>
              <div className="ui segment">
                <h1 className="ui center aligned header">
                  {course.displayName}
                </h1>
                <div className="ui divider"></div>
                {course.details.videoPath && (
                  <>
                    <Video
                      videoPath={course.details.videoPath}
                      posterPath={course.details.videoPosterPath}
                    />
                    <br />
                  </>
                )}
                <div dangerouslySetInnerHTML={{ __html: course.infoHtml }} />
                <div className="ui centered grid">
                  <div className="row">
                    <div className="column">
                      {loggedIn() ? (
                        <button
                          className="fluid ui primary button"
                          onClick={() => onEnroll()}
                          disabled={isEnrolling}
                        >
                          {isEnrolling ? 'Enrolling...' : 'Enroll'}
                        </button>
                      ) : (
                        <button
                          className="fluid ui primary button"
                          onClick={() => onSignup()}
                        >
                          Sign Up
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CourseDetails;
