import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import DsoaBanner from './DsoaBanner';
import SelectCourse from './SelectCourse';
import { RegistrationSteps, useAdSource } from 'dsoa-components';

const CoursesForState = () => {
  const { stateAbbreviation } = useParams();
  const navigate = useNavigate();
  useAdSource();

  const onSelectState = (selecteState) => {
    navigate(`/state/${selecteState}`);
  };

  return (
    <>
      <DsoaBanner text="Driving School of America" />
      <div className="ui main container">
        <RegistrationSteps step={1} />
        <br />
        <SelectCourse
          placeholder="Select another state"
          startingState={stateAbbreviation}
          onSelectState={onSelectState}
        />
        <br />
        <br />
      </div>
    </>
  );
};

export default CoursesForState;
