import { useLocation } from 'react-router-dom';

export const useAdSource = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const adsource = queryParams.get('adsource');

  if (adsource === 'google') {
    localStorage.setItem('adsource', 'google');
  }
};
