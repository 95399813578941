import './DsoaBanner.css';
import React, { useState, useEffect } from 'react';
import { StateDropDown } from 'dsoa-components';
import CourseCards from './CourseCards';
import { coursesByState, stateByAbbreviation } from 'dsoapi';

const SelectCourse = ({
  startingState,
  placeholder,
  onSelectState = () => {},
}) => {
  const [selectedState, setSelectedState] = useState(null);
  const [stateData, setStateData] = useState(null);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    if (startingState) {
      setSelectedState(startingState);
    }
  }, [startingState]);

  useEffect(() => {
    if (selectedState) {
      const fetchStateData = async () => {
        const response = await stateByAbbreviation(selectedState);
        setStateData(response.data);
      };
      const fetchCourses = async () => {
        const response = await coursesByState(selectedState);
        setCourses(response.data);
      };
      fetchStateData();
      fetchCourses();
    }
  }, [selectedState]);

  const stateSelected = (stateAbbreviation) => {
    onSelectState(stateAbbreviation);
    setSelectedState(stateAbbreviation);
  };

  return (
    <>
      <StateDropDown placeholder={placeholder} onSelectState={stateSelected} />
      {stateData && (
        <>
          <div className="ui center aligned container">
            <div className="dsoa-banner">
              <h1>{stateData.name}</h1>
            </div>
            <img
              className="ui centered small image"
              src={stateData.imageUrl}
              alt={`${stateData.name} seal`}
            />
            <div style={{ paddingTop: '10px' }} />
          </div>
          <br />
          <div className="ui container">
            <CourseCards courses={courses} />
          </div>
        </>
      )}
    </>
  );
};

export default SelectCourse;
