import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { studentUpdatePassword } from 'dsoapi';

const schema = yup
  .object({
    currentPassword: yup
      .string()
      .min(5, 'Password is too short')
      .required('Password is required'),
    newPassword: yup
      .string()
      .min(8, 'Password is too short')
      .matches(
        /^(?=.*\d)(?=.*\W)[\w\W]+$/,
        'Password must contain at least one number and one special character'
      )
      .required('Password is required'),
    confirmNewPassword: yup
      .string()
      .oneOf([yup.ref('newPassword')], "Passwords don't match")
      .required('Confirm password is required'),
  })
  .required();

const UpdatePassword = () => {
  const [statusMessage, setStatusMessage] = useState(null);
  const [updating, setUpdating] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = (data) => {
    const { currentPassword, newPassword } = data;
    setUpdating(true);
    studentUpdatePassword({ currentPassword, newPassword }).then((response) => {
      const { success, reason } = response.data;
      if (success) {
        setStatusMessage(<p>{'Password updated successfully.'}</p>);
        setValue('currentPassword', '');
        setValue('newPassword', '');
        setValue('confirmNewPassword', '');
      } else {
        setStatusMessage(<p className="error">{reason}</p>);
      }
      setUpdating(false);
    });
  };

  return (
    <form className="ui form" onSubmit={handleSubmit(onSubmit)}>
      <h4 className="ui dividing header">Update Password</h4>
      <div className="ui stackable grid">
        <div className="eight wide column">
          <div className="field">
            <label htmlFor="currentPassword">Current Password</label>
            <input
              {...register('currentPassword')}
              type="password"
              placeholder="Current Password"
            />
            <p className="error">{errors.currentPassword?.message}</p>
          </div>
          <div className="field">
            <label htmlFor="newPassword">
              New Password{' '}
              <span style={{ opacity: '.5', fontWeight: 'normal' }}>
                - at least 8 characters that include a number and symbol
              </span>
            </label>
            <input
              {...register('newPassword')}
              type="password"
              placeholder="New Password"
            />
            <p className="error">{errors.newPassword?.message}</p>
          </div>
          <div className="field">
            <label htmlFor="confirmNewPassword">Confirm New Password</label>
            <input
              {...register('confirmNewPassword')}
              type="password"
              placeholder="Confirm New Password"
            />
            <p className="error">{errors.confirmNewPassword?.message}</p>
          </div>
          <input
            className="ui primary button"
            type="submit"
            value={updating ? 'Updating...' : 'Update Password'}
            disabled={updating}
          />
          <br />
          <br />
          {statusMessage}
        </div>
        <div className="hide-mobile" style={{ height: '26px' }} />
        <div className="eight wide column"></div>
      </div>
    </form>
  );
};

export default UpdatePassword;
