import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { helpPageBySiteSlug } from 'dsoapi';
import Video from '../Video';
import { useAdSource } from '../useAdSource';

const HelpPage = ({ site, banner, setTitle = () => {} }) => {
  const { titleSlug } = useParams();
  const [helpPageData, setHelpPageData] = useState({
    title: '',
    contentHtml: '',
  });
  const navigate = useNavigate();
  useAdSource();

  useEffect(() => {
    const fetchData = async () => {
      const response = await helpPageBySiteSlug(site, titleSlug);
      setHelpPageData(response.data);
      setTitle(response.data.title);
    };
    fetchData();
  }, [site, titleSlug]);

  return (
    <>
      {banner}
      <div className="ui main container">
        <div className="ui segment">
          <h1 className="ui center aligned header">{helpPageData.title}</h1>
          <div className="ui divider"></div>
          {helpPageData.videoPath && (
            <>
              <Video
                videoPath={helpPageData.videoPath}
                posterPath={helpPageData.videoPosterPath}
              />
              <br />
            </>
          )}
          <div dangerouslySetInnerHTML={{ __html: helpPageData.contentHtml }} />
          <div style={{ height: '20px' }}></div>
          <div className="ui centered grid">
            <div className="row">
              <div className="column">
                <button
                  className="fluid ui primary button"
                  onClick={() => navigate('/')}
                >
                  Lets Get Started!
                </button>
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: '20px' }}></div>
      </div>
    </>
  );
};

export default HelpPage;
