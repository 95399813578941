import React, { useEffect, useState } from 'react';
import { helpPageBySite } from 'dsoapi';
import HelpPageCategory from './HelpPageCategory';
import { useAdSource } from '../useAdSource';

const HelpPageCategories = ({ site, banner, title, setTitle = () => {} }) => {
  const [categoryPages, setCategoryPages] = useState([]);
  useAdSource();

  setTitle('Help');

  useEffect(() => {
    const fetchData = async () => {
      const response = await helpPageBySite(site);
      setCategoryPages(response.data.categories);
    };
    fetchData();
  }, [site]);

  return (
    <>
      {banner}
      <div className="ui main container">
        <h1 className="ui center aligned header">{title}</h1>
        <div className="ui divider" />
        <img
          src="https://static-content.fedsafe.com/images/helppage/question.png"
          className="ui rounded fluid image"
          style={{ margin: '0 auto', maxWidth: '400px' }}
          alt="help"
        ></img>
        <br />
        {categoryPages.map((category) => (
          <HelpPageCategory category={category} key={category.title} />
        ))}
        <div style={{ height: '20px' }}></div>
      </div>
    </>
  );
};

export default HelpPageCategories;
